<template>
  <div>
    <!-- Listing-detail-header -->
    <section class="listing_detail_header">
      <div class="container">
        <div class="listing_detail_head white-text div_zindex row">
          <div class="col-md-9">
            <h2>{{ listing.advert.title }}</h2>
            <div class="car-location">
              <span
                ><i>{{ listing.advert.subtitle }}</i></span
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="price_info">
              <p>£{{ thousandsSeparators(listing.advert.price) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dark-overlay"></div>
    </section>
    <!-- /Listing-detail-header -->

    <section class="listing_other_info secondary-bg">
      <div class="container">
        <div id="filter_toggle" class="search_other" @click="navigateInventory">
          <i class="fa fa-arrow-left" aria-hidden="true"></i> Back To Pre-Owned
          Vehicles
        </div>
        <div id="info_toggle">
          <button type="button" @click="navigateContact">
            <i class="fa fa-car" aria-hidden="true"></i> Schedule Test Drive
          </button>
          <button type="button" @click="navigateContact">
            <i class="fa fa-money" aria-hidden="true"></i> Make an Offer
          </button>
          <button type="button" @click="navigateContact">
            <i class="fa fa-file-text" aria-hidden="true"></i> Request More Info
          </button>
        </div>
      </div>
    </section>

    <!--Listing-detail-->
    <section class="listing-detail">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <b-carousel
              id="homepage-carousel"
              v-model="carouselSlide"
              :interval="5000"
              controls
              indicators
              img-width="1024"
              img-height="768"
            >
              <b-carousel-slide
                v-for="(photo, index) in carouselImages"
                :key="index"
              >
                <img
                  slot="img"
                  class="d-block img-fluid vehicle-photo"
                  :src="photo.url"
                  :alt="photo.storageRef"
                />
              </b-carousel-slide>
            </b-carousel>
            <div class="main_features mt-3">
              <ul>
                <li>
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                  <h5>{{ formatYear(listing.vehicle.registrationDate) }}</h5>
                  <p>Reg Year</p>
                </li>
                <li>
                  <i class="fa fa-road" aria-hidden="true"></i>
                  <h5>{{ thousandsSeparators(listing.vehicle.mileage) }}</h5>
                  <p>Mileage</p>
                </li>
                <li>
                  <i class="fa fa-paint-brush" aria-hidden="true"></i>
                  <h5>{{ listing.vehicle.colour }}</h5>
                  <p>Colour</p>
                </li>
                <li>
                  <i class="fa fa-tachometer-alt" aria-hidden="true"></i>
                  <h5>{{ formatEngine(listing.vehicle.engine) }}L</h5>
                  <p>Engine Size</p>
                </li>
                <li>
                  <i class="fa fa-car" aria-hidden="true"></i>
                  <h5>{{ listing.vehicle.fuelType }}</h5>
                  <p>Fuel Type</p>
                </li>
                <li>
                  <i class="fa fa-cogs" aria-hidden="true"></i>
                  <h5>{{ listing.vehicle.transmission }}</h5>
                  <p>Transmission</p>
                </li>
              </ul>
            </div>
            <div class="listing_more_info mt-3">
              <div class="listing_detail_wrap">
                <div style="padding: 10px;">
                  <b-row>
                    <b-col>
                      <p>
                        <span class="sectionTitle">Description</span>
                        <br />
                        {{ listing.advert.description }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span class="sectionTitle">Details</span>
                      <b-table-simple
                        hover
                        small
                        borderless
                        class="detailsTable"
                      >
                        <b-tbody>
                          <b-tr>
                            <b-td>Mileage</b-td>
                            <b-td>{{
                              thousandsSeparators(listing.vehicle.mileage)
                            }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>First Registered</b-td>
                            <b-td>{{
                              formatDate(listing.vehicle.registrationDate)
                            }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>MOT Due</b-td>
                            <b-td>{{
                              formatDate(listing.vehicle.motDue)
                            }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>Servicing</b-td>
                            <b-td>{{ listing.advert.serviceHistory }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-td>Tax Band</b-td>
                            <b-td>{{ listing.vehicle.taxBand }}</b-td>
                          </b-tr>
                          <b-tr v-show="listing.vehicle.owners">
                            <b-td>Owners</b-td>
                            <b-td>{{ listing.vehicle.owners }}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row v-if="listing.advert.features.length">
                    <b-col>
                      <p>
                        <span class="sectionTitle">Features</span>
                        <br />
                        <span
                          v-for="(feature, index) in listing.advert.features"
                          :key="index"
                        >
                          {{ feature.Name
                          }}<template
                            v-if="index !== listing.advert.features.length - 1"
                            >,</template
                          >
                        </span>
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>

          <!--Side-Bar-->
          <aside class="col-md-3">
            <!--Vehicle specification-->
            <div class="sidebar_widget">
              <div class="widget_heading">
                <h5>
                  <i class="fa fa-folder-open" aria-hidden="true"></i>
                  Specification
                </h5>
              </div>
              <b-table-simple hover small borderless class="detailsTable">
                <b-tbody>
                  <b-tr>
                    <b-td>Make</b-td>
                    <b-td>{{ listing.vehicle.make }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Model</b-td>
                    <b-td>{{ listing.vehicle.model }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Variant</b-td>
                    <b-td>{{ listing.vehicle.variant }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Body Type</b-td>
                    <b-td>{{ listing.vehicle.bodyType }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Colour</b-td>
                    <b-td>{{ listing.vehicle.colour }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Seats</b-td>
                    <b-td>{{ listing.vehicle.seats }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Engine</b-td>
                    <b-td>{{ listing.vehicle.engine }}cc</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Fuel Type</b-td>
                    <b-td>{{ listing.vehicle.fuelType }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Power (BHP)</b-td>
                    <b-td>{{ listing.vehicle.power }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Transmission</b-td>
                    <b-td>{{ listing.vehicle.transmission }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>

            <!--Share widget-->
            <div class="sidebar_widget">
              <div class="widget_heading">
                <h5>
                  <i class="fa fa-share-alt" aria-hidden="true"></i> Share
                  Listing
                </h5>
              </div>
              <SocialMediaSharing
                :title="
                  'Check out this ' +
                    listing.vehicle.make +
                    ' from Midland Auto Sales'
                "
                :description="
                  'I found this great ' +
                    listing.advert.title +
                    ' from Midland Auto Sales - check it out!'
                "
                :url="'https://midlandautosales.co.uk/cardetail/' + listing.id"
              />
            </div>
          </aside>
          <!--/Side-Bar-->
        </div>
        <div class="space-20"></div>
        <div class="divider"></div>

        <h2 class="text-center mb-4">Featured Cars</h2>

        <FeaturedCars />
      </div>
    </section>
    <!--/Listing-detail-->
  </div>
</template>

<script>
  import moment from "moment"
  import { mapGetters } from "vuex"

  export default {
    name: "CarDetail",
    components: {
      SocialMediaSharing: () => import("../components/SocialMediaSharing"),
      FeaturedCars: () => import("../components/FeaturedCars"),
    },
    data() {
      return {
        listing: {},
        carouselSlide: 0,
      }
    },
    computed: {
      ...mapGetters("stock", ["getAllVehicles"]),

      carouselImages() {
        return this.listing.images?.length
          ? this.listing.images
          : [
              {
                url:
                  "https://firebasestorage.googleapis.com/v0/b/midlandautosales-c2dcf.appspot.com/o/ComingSoon.jpg?alt=media",
                storageRef: "Images coming soon",
              },
            ]
      },
    },
    beforeMount() {
      if (this.$route.params.id) {
        const i = this.getAllVehicles.findIndex(
          (vehicle) => vehicle.id === this.$route.params.id
        )
        if (i > -1) {
          this.listing = this.getAllVehicles[i]
        } else {
          this.$router.push({ path: "/pre-owned" })
        }
      } else {
        this.$router.push({ path: "/pre-owned" })
      }
    },
    methods: {
      formatYear(dt) {
        return moment(dt).format("YYYY")
      },
      formatDate(dt) {
        return moment(dt).format("DD/MM/YYYY")
      },
      formatEngine(size) {
        return (Math.round(size) / 1000).toFixed(1)
      },
      navigateInventory() {
        this.$router.push({ path: "/pre-owned" })
      },
      navigateContact() {
        this.$router.push({ name: "Contact" })
      },
    },
  }
</script>

<style scoped>
  .page-header {
    background-image: url("../../public/assets/images/midland-auto-sales-inventory-banner.jpg");
  }
  .vehicle-photo {
    margin: auto;
    width: auto;
    max-height: 500px !important;
  }
  .sectionTitle {
    color: #DE3732;
    font-weight: 600;
    font-size: 13pt;
  }
  .field-label {
    font-size: 9pt;
  }
  .detailsTable td {
    font-size: 11pt;
  }
  .listing_detail_header {
    background-image: url("../../public/assets/images/midland-auto-sales-cardetail-audi.jpg");
  }

  .price_info p {
    color: white;
  }
</style>
